import Cookies from 'universal-cookie';

export let filterByState = (data = [], state = '') => {
    if (data.length > 0 && state !== '') {
        data = data.filter(({ node }) => {
            let brokerState = node.stateCode;
            //console.log(brokerState.trim() == state.trim())
            return brokerState.trim() == state.trim();
        })
        return data;
    }
    return [];
}

export let fillByInterState = (data = [], state = '', showRecord=false) => {
    if (data.length > 0 && state !== '') {
        data = data.filter(({ node }) => {
            let brokerState = node.stateCode;
            showRecord = brokerState.trim() == state.trim();
            
            if(!showRecord)
                showRecord = node.interstate;

            return showRecord;

        })
        return data;
    }
    return [];
}

export let fillBrokerTiles = (data = [], postcodeParts = '', postcode = '', showRecord = false) => {
    if (data.length > 0 ) {
        data = data.filter(({ node }) => {
            let brokerState = node.stateCode;
            let broker_postcode = node.broker_postcode.split(",");
            showRecord = broker_postcode.indexOf(postcode) != -1;

            if( !showRecord ){
                let stateCode = '';
                if(postcodeParts.length == 3) {
                    stateCode = postcodeParts[2];
                    showRecord = brokerState.trim() == stateCode.trim();
                }                
            }
            // if( !showRecord )
            //     showRecord = node.interstate;

            console.log(showRecord);
            return showRecord;
        });
        return data;
    }
    return [];
}

export let filterByInterState = (data = []) => {
    if (data.length > 0 ) {
        data = data.filter(({ node }) => {
            return node.interstate;
        })
        return data;
    }
    return [];
}


export const log = (logVar = '', separator = '-------') => {
    return false;
    console.log(separator)
    console.log(logVar)
    console.log(separator)
}

export const cookieWrite = (variable, value = '') => {
    let cookies = new Cookies();
    cookies.set(variable, value, { path: '/' });
    return cookies.get(variable)
}

export const cookieRead = (variable) => {
    let cookies = new Cookies();
    return cookies.get(variable)
}

export const oWindow = (() => {
    return {
        read: (readVar) => {
            if (typeof window != 'undefined') {
                return window.readVar;
            }
        },
        write: (writeVar = '', value = '') => {
            if (typeof window != 'undefined') {
                if (!!writeVar && !!value) {
                    window[writeVar] = value;
                    return !!window.writeVar;
                }
            }
        }
    }
})()

export const removeCookie = (removeVar = '') => {
    if (!!removeVar) {
        let cookies = new Cookies()
        cookies.remove(removeVar, { path: '/' })
    }
}

export const formatResponseTime = (responseTime) => {
    if (!responseTime) {
        return '1 min'; // default time according to new design
    } else {
        let parts = responseTime.split(" ");
        if (parts.length > 1) {
            if (parts[0] == '0') {
                return '1 min';
            } else {
                return parts[0] + ' ' + String(parts[1]);
            }
        } else {
            return '1 min';
        }
    }
}

export const host = 'https://hashfinancialgroup.com/wp-json';
//export const hfg_host = 'https://hashfinancialgroup.com';